import { baseAPI } from "./base-api";
import { USERS } from "./tags";

export const authAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: "auth/signout",
        method: "PUT",
      }),
      invalidatesTags: [USERS],
    }),
    signUp: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { useSignUpMutation, useLoginMutation } = authAPI;
