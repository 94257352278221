import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import RHFTextField from "../../../components/rhf-textField";
import { useLoginMutation } from "../../../services/auth-api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../../assets/logo.png";

const Login = () => {
    const [mutation] = useLoginMutation();
    const router = useNavigate();

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validationSchema: Yup.object({
            email: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await mutation({ email: values.email, password: values.password }).unwrap();
                console.log("status", response)
                if (response.status === 'success') {
                    toast.success(response.message || "Sign in successfully!");
                    router("/");
                } else if (response.status === 'error') {
                    toast.error("Something went wrong!");
                }
            } catch (error) {
                toast.error(error.message ?? "Something went wrong!");
            }
        },
    });

    return (
        <div className="wrapper">
            <div className="form-wrapper box-shadow">
                <div className='flex home-logo'><img src={LogoImg} alt="" style={{ width: '40% ' }} /></div>
                <div className="content auth-content">
                    <div className="logo"></div>
                    <div className="heading mt-10">
                        <h3>StainSoft Servizi Per Aziende Gestione Dispensa</h3>
                    </div>
                    <form className="form form-registry" onSubmit={formik.handleSubmit}>
                        <div className="form-content">
                            <RHFTextField type="text" name="email" placeholder="nome utente" count={false} formik={formik} />
                            <RHFTextField type="password" name="password" placeholder="parola d'ordine" count={false} formik={formik} />
                            <button type="submit" className="primary-bg-color button">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
