import { PRODUCT } from "../tags";
import { baseAPI } from "../base-api";

export const dropdownAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProvidersDropdown: builder.query({
      query: ({ params }) => ({
        url: "/get-fornitori-dropdown",
        method: "GET",
        params: {
          ...(params?.ragione && { ragione: params.ragione }),
        }
      }),
      providesTags: [PRODUCT],
    }),
    getGruppoDropdown: builder.query({
      query: () => ({
        url: "/get-gruppo-dropdown",
        method: "GET",
      }),
      providesTags: [PRODUCT],
    }),
    getPersonalDropdown: builder.query({
      query: () => ({
        url: "/get-personale-dropdown",
        method: "GET",
      }),
      providesTags: [PRODUCT],
    }),
    getPositionDropdown: builder.query({
      query: () => ({
        url: "/get-posizione-dropdown",
        method: "GET",
      }),
      providesTags: [PRODUCT],
    }),
    getUserPersonalByIdDropdown: builder.query({
      query: ({ id, params }) => ({
        url: `/get-user-personale-dropdown?id_pe=${id}`,
        method: "GET",
        params: {
          ...(params?.descrizione && { descrizione: params.descrizione }),
          ...(params?.gruppo && { gruppo: params.gruppo })
        }
      }),
      providesTags: [PRODUCT],
    }),
    getUserPersonalDropdown: builder.query({
      query: ({ id, descrizione, gruppo, tipo }) => ({
        url: `/get-user-personale-dropdown?id_pe=${id}${descrizione ? `&descrizione=${descrizione}` : ""}${gruppo ? `&gruppo=${gruppo}` : ""}${tipo ? `&tipo=${tipo}` : ""}`,
        method: "GET",
      }),
      providesTags: [PRODUCT],
    }),
    getRichiestaDropdown: builder.query({
      query: () => ({
        url: "/get-richiesta-dropdown",
        method: "GET",
      }),
      providesTags: [PRODUCT],
    }),
    getPosizioneDropdown: builder.query({
      query: () => ({
        url: `/get-posizioni`,
        method: "GET",
      }),
      invalidatesTags: [PRODUCT]
    }),
    getMagazzinoDropdown: builder.query({
      query: () => ({
        url: `/get-magazzino-dropdown`,
        method: "GET",
      }),
      invalidatesTags: [PRODUCT]
    }),
  }),
});

export const {
  useGetProvidersDropdownQuery,
  useGetGruppoDropdownQuery,
  useGetPersonalDropdownQuery,
  useGetPositionDropdownQuery,
  useGetUserPersonalDropdownQuery,
  useGetUserPersonalByIdDropdownQuery,
  useGetRichiestaDropdownQuery,
  useGetPosizioneDropdownQuery,
  useGetMagazzinoDropdownQuery
} = dropdownAPI;
