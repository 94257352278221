import React from "react";
import { Routes, Route } from "react-router-dom";
import GroupRegistry from "./sections/home/group-registry/group-registry";
import ProductRegistry from "./sections/home/product-registry/product-registry";
import SupplyRegistry from "./sections/home/supply-registry/supply-registry";
import Home from "./sections/home/home";
import AssignmentControl from "./sections/home/assignment-control/assignment-control";
import EmployeeRegistry from "./sections/home/employee-registry/employee-registry";
import ProductInventory from "./sections/home/product-inventory/product-inventory";
import Login from "./sections/auth/login/login";
import AggiornaMagazzino from "./sections/home/aggiorna-magazzino/aggiorna-magazzino";
import ElencoSpesa from "./sections/home/elenco-spesa/elenco-spesa";
import NascondiArticoli from "./sections/home/nascondi-articoli/nascondi-articoli";
import InventarioProdotti from "./sections/home/inventario-prodotti/inventario-prodotti";
import GestioneSpesa from "./sections/home/gestione-spesa/gestione-spesa";
import AnagrafePosizioni from "./sections/home/anagrafe-posizioni/anagrafe-posizioni";
import StampaEtichette from "./sections/home/stampa-etichette/stampa-etichette";
import 'antd/dist/reset.css';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/employee-registry" element={<EmployeeRegistry />} />
        <Route path="/supply-registry" element={<SupplyRegistry />} />
        <Route path="/group-registry" element={<GroupRegistry />} />
        <Route path="/product-registry" element={<ProductRegistry />} />
        <Route path="/assignment-control" element={<AssignmentControl />} />
        <Route path="/product-inventory" element={<ProductInventory />} />
        <Route path="/aggiorna-magazzino" element={<AggiornaMagazzino />} />
        <Route path="/gestione-spesa" element={<GestioneSpesa />} />
        <Route path="/elenco-spesa" element={<ElencoSpesa />} />
        <Route path="/inventario-prodotti" element={<InventarioProdotti />} />
        <Route path="/nascondi-articoli" element={<NascondiArticoli />} />
        <Route path="/anagrafe-posizioni" element={<AnagrafePosizioni />} />
        <Route path="/stampa-etichette" element={<StampaEtichette />} />
      </Routes>
    </div>
  );
}

export default App;
