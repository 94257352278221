import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import RHFTextField from "../../../components/rhf-textField";
import { Link } from "react-router-dom";
import { useCreateSupplyMutation, useDeleteSupplyMutation, useGetSupplyQuery, useUpdateSupplyMutation } from "../../../services/supply-registry/supply-registry-api";
import toast from "react-hot-toast";
import withAuthGuard from "../../../hoc/with-auth-guards";
import LoadingIcon from "../../../assets/loading.svg";
import { getSessionStorage } from "../../../utils/session-storage";

const initialValues = {
  ragione: "",
  email: "",
  cellulare: "",
};

const SupplyRegistry = () => {
  const user = getSessionStorage("user");
  const [actionType, setActionType] = useState("add");
  const [supplyObj, setSupplyObj] = useState(null);
  const [createSupply] = useCreateSupplyMutation();
  const [updateSupply] = useUpdateSupplyMutation();
  const [deleteSupply] = useDeleteSupplyMutation();

  const validationSchema = Yup.object({
    ragione: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    cellulare: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const supply = {
        ragione: values.ragione,
        email: values.email,
        cellulare: values.cellulare,
        database: user?.database,
      };
      try {
        if (actionType === "add") {
          await createSupply(supply).unwrap();
          console.log("addddddd");
          toast.success("Supply created successfully");
        } else if (actionType === "edit") {
          await updateSupply({
            id: supplyObj.id_fo,
            ...supply,
          }).unwrap();
          toast.success("Supply updated successfully");
          setActionType("delete");
        }
        formik.resetForm();
      } catch (error) {
        toast.error("An error occurred while processing the request");
      }
    },
  });

  const handleUpdate = (values) => {
    setSupplyObj(values);
    setActionType("edit");
    formik.setValues({
      ragione: values.ragione,
      email: values.email,
      cellulare: values.cellulare,
    });
  };

  const handleDelete = async () => {
    try {
      await deleteSupply({ id: supplyObj.id_fo }).unwrap();
      toast.success("Employee deleted successfully");
      setActionType("delete");
      formik.resetForm();
    } catch (error) {
      toast.error("An error occurred while deleting the employee");
    }
  };

  // const getValue = formik.getFieldProps('ragione');
  const { data, isLoading } = useGetSupplyQuery();

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>ANAGRAFE FORNITORE</h2>
          <form onSubmit={formik.handleSubmit}>
            {/* form fields */}
            <div className="form">
              <div className="form-content">
                <RHFTextField type="text" name="ragione" placeholder="Ragione Sociale Fornitore" formik={formik} />
                <RHFTextField type="email" name="email" placeholder="Email Fornitore" formik={formik} />
                <RHFTextField type="text" name="cellulare" placeholder="NUMERO DI TELEFONO" formik={formik} />
                {/* button */}
                <div className="form-group">
                  {actionType === "edit" && (
                    <>
                      <button type="button" className="close-button-bg button" onClick={handleDelete}>
                        CANCELLA
                      </button>
                      <button
                        type="button"
                        className="close-button-bg-color button"
                        onClick={() => {
                          formik.handleReset();
                          setActionType("add");
                        }}
                      >
                        ANNULLA
                      </button>
                    </>
                  )}

                  <button type="submit" className="primary-bg-color button">
                    {actionType === "edit" ? "AGGIORNA" : "SALVA"}
                  </button>
                </div>
              </div>
              <div className="form-heading">
                <h3>ELENCO FORNITORE</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item) => (
                        <div className="item" key={item.id_pe}>
                          <p>{item.ragione}</p>
                          <div>
                            <button type="button" className="primary-bg-color button" onClick={() => handleUpdate(item)}>
                              MODIFICA
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="submit-button">
                <Link to="/" className="primary-bg-color button">
                  CHIUDI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(SupplyRegistry);
