import React from "react";
import CustomModal from "../../../../components/custom-modal";
import { useFormik } from "formik";
import RHFTextField from "../../../../components/rhf-textField";
import * as Yup from "yup";
import RHFSelect from "../../../../components/rhf-select";
import RHFDatePicker from "../../../../components/rhf-datepicker";
import { useGetProvidersDropdownQuery } from "../../../../services/dropdown/dropdown-api";
import toast from "react-hot-toast";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import { getSessionStorage } from "../../../../utils/session-storage";

const CongModal = ({ open, onClose, stampaObj }) => {
  const { data: getProvider } = useGetProvidersDropdownQuery({});
  const user = getSessionStorage("user");
  console.log(user);

  console.log(stampaObj?.descrizione);

  const validationSchema = Yup.object({
    produzione: Yup.date().nullable().required("Required"),
    scadenza: Yup.date().nullable().required("Required"),
  });

  const initialValues = {
    produzione: null,
    scadenza: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      generatePdf(values);
    },
  });

  const generatePdf = (values) => {
    const content = document.createElement("div");
    content.innerHTML = `
      <div id="assignment">
      <div class="item">
        <h2>${`BAGNO SIRENA`}</h2>
        <h2>${`P.I. 85T46843939`}</h2>
      </div>
      <div class="item">
          <h2>DESCRIZIONE PRODOTTO</h2>
          <h2>${stampaObj?.descrizione}</h2>
        </div>
        <div class="stampa-group">
          <div class="item">
            <h2>DATA PRODUZIONE</h2>
            <h2>${dayjs(values.produzione).format("DD-MM-YYYY")}</h2>
          </div>
          <div class="item">
            <h2>SCADENZA</h2>
            <h2>${dayjs(values.scadenza).format("DD-MM-YYYY")}</h2>
          </div>
        </div>
      </div>
    `;

    const style = document.createElement("style");
    style.innerHTML = `
      #assignment {
        width: 551px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding-top: 10px;    
      }
      h2 {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        color: #000;
        margin: 0;
        line-height: 15px;
        padding: 0;
      }
      .stampa-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 400px; 
        margin: auto;
      }
      .item {
        line-height: 15px;
        flex: 1;
        text-align: center;
        padding-bottom: 15px;
      }
    `;
    document.head.appendChild(style);

    document.body.appendChild(content);

    const doc = new jsPDF("px", "px", [551, 315]);
    doc.internal.pageSize.setWidth(551);
    doc.internal.pageSize.setHeight(315);
    doc.html(content).then(() => {
      doc.save(`CONG-${dayjs().format("DD-MM-YYYY")}.pdf`);
      toast.success("PDF generated successfully");
      document.head.removeChild(style);
      document.body.removeChild(content);
    });
  };

  return (
    <CustomModal open={open} onClose={onClose} title="CONGELAMENTO">
      <div className="custom-modal">
        <form onSubmit={formik.handleSubmit}>
          <div className="form">
            <div className="form-group form-group-wrap">
              <RHFDatePicker name="produzione" placeholder="DATA PRODUZIONE" formik={formik} />
              <RHFDatePicker placeholder="DATA SCADENZA" name="scadenza" formik={formik} />
            </div>
            <div className="form-group">
              <button type="submit" className="primary-bg-color button">
                STAMPA ETICHETTA
              </button>
              <button type="button" className="light-yellow-button-bg button" onClick={onClose}>
                ANNULLA
              </button>
            </div>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default CongModal;
