import { baseAPI } from "../base-api";
import { GROUP } from "../tags";

export const nascondiArticoliAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    updateGroup: builder.mutation({
      query: ({ id }) => ({
        url: `/update-gruppo/${id}`,
        method: "PUT",
      }),
      invalidatesTags: [GROUP],
    }),
  }),
});

export const { useUpdateGroupMutation } = nascondiArticoliAPI;
