import AuthGuard from "../guards/auth-guards";

const withAuthGuard = (Component) => {
  return function WithAuthGuard(props) {
    return (
      <AuthGuard>
        <Component {...props} />
      </AuthGuard>
    );
  };
};
export default withAuthGuard;