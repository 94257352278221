import { baseAPI } from "../base-api";
import { PRODUCT } from "../tags";

export const supplyRegistryAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        createProduct: builder.mutation({
            query: (body) => ({
                url: "/create-prodotti",
                method: "POST",
                body,
            }),
            invalidatesTags: [PRODUCT]
        }),
        getProduct: builder.query({
            query: ({ search }) => ({
                url: `/get-prodotti${search ? `?search=${search}` : ''}`,
                method: "GET",
            }),
            providesTags: [PRODUCT]
        }),
        updateProduct: builder.mutation({
            query: ({ id, body }) => ({
                url: `/update-prodotti/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: [PRODUCT]
        }),
        deleteProduct: builder.mutation({
            query: ({ id }) => ({
                url: `/delete-prodotti/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [PRODUCT]
        }),
        assignProduct: builder.mutation({
            query: ({ ...body }) => ({
                url: `/assign-prodotti`,
                method: "POST",
                body
            }),
            invalidatesTags: [PRODUCT]
        }),
        updateAssignProduct: builder.mutation({
            query: ({ id, body }) => ({
                url: `/assign-prodotti/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: [PRODUCT]
        }), 
        updateQtaProduct: builder.mutation({
            query: ({ id, body }) => ({
                url: `/update-qta-magazzino/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: [PRODUCT]
        }), 
    }),
});

export const {
    useCreateProductMutation,
    useGetProductQuery,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useAssignProductMutation,
    useUpdateAssignProductMutation,
    useUpdateQtaProductMutation,
} = supplyRegistryAPI;
