export const initialValues = {
    suppiler: "",
    gruppo: "",
    productType: "",
    description: "",
    um: "",
    min: "",
    media: "",
    posizione: "",
    dipendente: "",
  };

export const productsDropdown = [
    { id: 1, label: 'MAT. PRIMA' },
    { id: 2, label: 'PRODUZIONE' }
];