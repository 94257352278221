import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TAGS } from "./tags";

const baseQuery = async (args, api, extraOptions) => {
  const response = await fetchBaseQuery({
    baseUrl: 'https://stainsoft-servizi.com/backend',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState()).auth.accessToken;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  })(args, api, extraOptions);
  if (response.error && response.error.status === 401) {
    // Redirect to login page
    window.location.href = '/login';
  }
  return response
};

export const baseAPI = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: TAGS,
  endpoints: () => ({}),
});
