import { baseAPI } from "../base-api";
import { ASSIGNMENT } from "../tags";

export const assignmentControlAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        getMagazzinoFilter: builder.query({
            query: ({ params }) => ({
                url: "/filter-magazzino",
                method: "GET",
                params: {
                    ...(params?.descrizione && { descrizione: params?.descrizione }),
                    ...(params?.gruppo && { gruppo: params?.gruppo }),
                    ...(params?.tipo && { tipo: params?.tipo }),
                    ...(params?.persona && { persona: params?.persona }),
                }
            }),
            providesTags: [ASSIGNMENT]
        }),

    }),
});

export const {
    useGetMagazzinoFilterQuery
} = assignmentControlAPI;
