import React, { useState } from "react";
import { Link } from "react-router-dom";
import withAuthGuard from "../../../hoc/with-auth-guards";
import { useGetUserPersonalByIdDropdownQuery, useGetUserPersonalDropdownQuery } from "../../../services/dropdown/dropdown-api";
import { getSessionStorage } from "../../../utils/session-storage";
import AggiornaMagazzinoModal from "./aggiorna-magazzino-modal/aggiorna-magazzino-modal";
import LoadingIcon from "../../../assets/loading.svg";

const AggiornaMagazzino = () => {
  const [isQtaOpenModal, setIsQtaOpenModal] = useState(false);
  const [getAggiornaObj, setGetAggiornaObj] = useState({});
  console.log(getAggiornaObj)
  const user = getSessionStorage("user");
  const [descrizione, setDescrizione] = useState("");
  const [gruppo, setGruppo] = useState("");
  // descrizione: description, gruppo: gruppo
  const { data: getUser, isLoading } = useGetUserPersonalByIdDropdownQuery({ id: user?.id_pe, params: { ...(descrizione && { descrizione }), ...(gruppo && { gruppo }) } });
  const { data: getPersonal } = useGetUserPersonalDropdownQuery({ id: user?.id_pe });

  const handleQtaMinModal = () => {
    setIsQtaOpenModal(!isQtaOpenModal);
  }

  return (
    <>
      <div className="wrapper">
        <div className="form-wrapper box-shadow">
          <div className="content">
            <h2>AGGIORNA QTA PRODOTTO</h2>
            <form>
              {/* form fields */}
              <div className="form">
                <div className="form-content">
                <select type="text" name="cercaPordotto" placeholder="Cerca Pordotto" onChange={(e) => setDescrizione(e.target.value)}>
                  <option disabled value="" selected>
                    Select ...
                  </option>
                  {getPersonal?.data?.map((item, index) => (
                    <option value={item.descrizione} key={index}>
                      {item.descrizione}
                    </option>
                  ))}
                </select>
                <select type="text" name="cercaPordotto" placeholder="Cerca Gruppo" onChange={(e) => setGruppo(e.target.value)}>
                  <option disabled value="" selected>
                    Select ...
                  </option>
                  {getPersonal?.data?.map((item, index) => (
                    <option value={item.gruppo} key={index}>
                      {item.gruppo}
                    </option>
                  ))}
                </select>
                </div>
                <div className="form-heading">
                  <h3>ELENCO PRODOTTI</h3>
                  <h3>QTA</h3>
                </div>
                <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {getUser?.data?.length > 0 ? (
                      getUser?.data?.map((item) => (
                        <div className="item" key={item.id_pe}>
                          <p>{item.descrizione}</p>
                          <p className="cursor-pointer">{item.qta_min}</p>
                          <div>
                            <button type="button" className="primary-bg-color button" onClick={() => { handleQtaMinModal(); setGetAggiornaObj(item) }}>
                              MODIFICA
                            </button>
                          </div>
                        </div>
                      ))
                    ) : <div><h5>No results found</h5></div>}
                  </>
                )}
              </div>
                <div className="content">
                  {getUser?.data?.map((item, index) => (
                    <div className="item" key={index}>
                      <p>{item.descrizione}</p>
                      <p className="cursor-pointer" onClick={() => { handleQtaMinModal(); setGetAggiornaObj(item) }}>{item.qta_min}</p>
                    </div>
                  ))}
                </div>
                <div className="submit-button">
                  <Link to="/" className="primary-bg-color button">
                    CHIUDI
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AggiornaMagazzinoModal open={isQtaOpenModal} onClose={handleQtaMinModal} getAggiornaObj={getAggiornaObj} />
    </>
  );
};

export default withAuthGuard(AggiornaMagazzino);
