import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import RHFTextField from "../../../components/rhf-textField";
import toast from "react-hot-toast";
import { useCreateEmployeeMutation, useDeleteEmployeeMutation, useGetEmployeeQuery, useUpdateEmployeeMutation } from "../../../services/employee-registry/employee-registry-api";
import { Link } from "react-router-dom";
import withAuthGuard from "../../../hoc/with-auth-guards";
import LoadingIcon from "../../../assets/loading.svg";

const EmployeeRegistry = () => {
  const [actionType, setActionType] = useState("add");
  const [employeeObj, setEmployeeObj] = useState(null);
  const [createEmployee] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [deleteEmployee] = useDeleteEmployeeMutation();

  const validationSchema = Yup.object({
    persona: Yup.string().required("Required"),
    telefono: Yup.string().required("Required"),
    password: actionType === 'edit' ? Yup.string().optional() : Yup.string().required("Required"),
    // azienda: Yup.string().required("Required"),
    // part_iva: Yup.string().required("Required"),
    email: actionType === 'edit' ? Yup.string().optional() : Yup.string().email("Invalid email format").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      persona: "",
      telefono: "",
      password: "",
      email: "",
      // azienda: "",
      // part_iva: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setActionType("add");
        if (actionType === "add") {
          await createEmployee({
            persona: values.persona,
            telefono: values.telefono,
            email: values.email,
            // azienda: values.azienda,
            // part_iva: values.part_iva,
            password: values.password,
            role: "user",
          }).unwrap();
          toast.success("Employee created successfully");
        } else if (actionType === "edit") {
          await updateEmployee({ id: employeeObj.id_pe, body: values }).unwrap();
          toast.success("Employee updated successfully");
          setActionType("delete");
        }
        formik.resetForm();
      } catch (error) {
        toast.error("An error occurred while processing the request");
      }
    },
  });

  const handleUpdate = (values) => {
    setEmployeeObj(values);
    setActionType("edit");
    formik.setValues({
      persona: values.persona,
      telefono: values.telefono,
      // part_iva: values.part_iva,
      // azienda: values.azienda,
      email: values.email,
      password: values?.password
    });
  };

  const handleDelete = async () => {
    try {
      await deleteEmployee({ id: employeeObj.id_pe }).unwrap();
      toast.success("Employee deleted successfully");
      setActionType("delete");
      formik.resetForm();
    } catch (error) {
      toast.error("An error occurred while deleting the employee");
    }
  };
  const getValue = formik.getFieldProps("persona");
  const { data, isLoading } = useGetEmployeeQuery({ search: getValue?.value });

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>ANAGRAFE PERSONALE</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="form">
              <div className="form-content">
                {/* form fields */}
                <RHFTextField type="text" name="persona" placeholder="nome dipendente" formik={formik} />
                <div className="form-group">
                  <RHFTextField type="number" name="telefono" placeholder="telefono dipendente" formik={formik} />
                  <RHFTextField type="password" name="password" placeholder="password di accesso" formik={formik} />
                </div>
                {/* <div className="form-group">
                  <RHFTextField type="text" name="azienda" placeholder="AZIENDA" formik={formik} />
                  <RHFTextField type="text" name="part_iva" placeholder="PART IVA" formik={formik} />
                </div> */}
                <RHFTextField type="email" name="email" placeholder="email personale" formik={formik} disabled={actionType === 'edit'} />
                {/* buttons */}
                <div className="form-group">
                  {actionType === "edit" && (
                    <>
                      <button type="button" className="close-button-bg button" onClick={handleDelete}>
                        CANCELLA
                      </button>
                      <button type="button" className="close-button-bg-color button" onClick={() => {formik.handleReset(); setActionType('add')}}>
                      ANNULLA
                    </button>
                    </>
                  )}
                  <button type="submit" className="primary-bg-color button">
                    {actionType === "edit" ? "AGGIORNA" : "SALVA"}
                  </button>
                </div>
              </div>
              {/* employee list */}
              <div className="form-heading">
                <h3>ELENCO PERSONALE ADDETTO</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item) => (
                        <div className="item" key={item.id_pe}>
                          <p>{item.persona}</p>
                          <div>
                            <button type="button" className="primary-bg-color button" onClick={() => handleUpdate(item)}>
                              MODIFICA
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* close button */}
              <div className="submit-button">
                <Link to="/" className="primary-bg-color button">
                  CHIUDI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(EmployeeRegistry);
