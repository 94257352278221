import React from "react";
import CloseIcon from "../assets/close-icon.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const CustomModal = ({ open, onClose, title, children }) => {
    return (
        <Modal open={open} onClose={onClose} center closeIcon>
            <div className="close-modal flex">
                <h2>{title}</h2>
                <img src={CloseIcon} alt="" onClick={onClose} className="cursor-pointer" />
            </div>
            {children}
        </Modal>
    );
};

export default CustomModal;
