import React, { useState } from "react";
import { Link } from "react-router-dom";
import withAuthGuard from "../../../hoc/with-auth-guards";
import { useGetProvidersDropdownQuery, useGetRichiestaDropdownQuery } from "../../../services/dropdown/dropdown-api";
import LoadingIcon from "../../../assets/loading.svg";
import { useSendEmailMutation } from "../../../services/gestione-spesa/gestione-spesa-api";
import toast from "react-hot-toast";

const GestioneSpesa = () => {
    const [buttonConfig, setButtonConfig] = useState([
        { label: "INVIA EMAIL", color: "hsla(39, 100%, 68%, 1)", type: "inviteEmail", disable: true },
        { label: "WHATSAPP", color: "rgb(108 102 133)", type: "whatsapp", disable: true },
    ]);
    const [ragione, setRagione] = useState("");
    const { data: getRichiesta } = useGetRichiestaDropdownQuery({});
    const [mutation] = useSendEmailMutation();
    const [gestioneObj, setGestioneObj] = useState({});
    const { data: getProviders, isLoading } = useGetProvidersDropdownQuery({ params: { ...(ragione && { ragione }) } });

    const handleConfigButton = async(type) => {
        if (type === "inviteEmail") {
            try {
               await mutation({
                    id_fo: gestioneObj?.id_fo,
                    email: gestioneObj?.email
                }).unwrap();
                toast.success("Email sent successfully!");
            } catch (error) {
                toast.error("Failed to send email. Please try again later.");
            }
        }
    };

    console.log(gestioneObj);

    const handleInvite = (item) => {
        const updatedButtons = buttonConfig.map((button) => {
            if (button.type === "inviteEmail" || button.type === "whatsapp") {
                return { ...button, disable: false };
            }
            return button;
        });
        setButtonConfig(updatedButtons);
        setGestioneObj(item);

        setTimeout(() => {
            setButtonConfig([
                { label: "INVIA EMAIL", color: "hsla(39, 100%, 68%, 1)", type: "inviteEmail", disable: true },
                { label: "WHATSAPP", color: "rgb(108 102 133)", type: "whatsapp", disable: true },
            ]);
        }, 10000);
    };

    return (
        <div className="wrapper">
            <div className="form-wrapper box-shadow">
                <div className="content">
                    <h2>GESTIONE SPESA</h2>
                    <form>
                        {/* form fields */}
                        <div className="form">
                            <select name="cercaProdotto" placeholder="ELENCO FORNITORI CON SPESA" value={ragione} onChange={(e) => setRagione(e.target.value)}>
                                <option value="" disabled>
                                    Select ...
                                </option>
                                {getRichiesta?.data?.map((item, index) => (
                                    <option key={index} value={item?.id_fo}>
                                        {item.fornitore}
                                    </option>
                                ))}
                            </select>
                            <div className="form-heading">
                                <h3>ELENCO PRODOTTI</h3>
                            </div>
                            <div className="content">
                                {isLoading ? (
                                    <div className="flex justify-center">
                                        <img src={LoadingIcon} alt="loading" />
                                    </div>
                                ) : (
                                    <>
                                        {getProviders?.data?.length > 0 ? (
                                            getProviders?.data?.map((item) => (
                                                <div className="item" key={item.id_pe} style={{ padding: "12px" }}>
                                                    <p>{item.ragione}</p>
                                                    <div>
                                                        <button type="button" className="button primary-bg-color" onClick={() => handleInvite(item)} style={{ padding: "11px 22px" }}>
                                                            INVIA
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div>
                                                <h5>No results found</h5>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="assignment-btn">
                                {buttonConfig.map((item, index) => (
                                    <button
                                        type="button"
                                        className="button"
                                        key={index}
                                        style={{ background: item.color, cursor: item.disable ? "not-allowed" : "pointer" }}
                                        onClick={() => handleConfigButton(item.type)}
                                        disabled={item.disable}
                                    >
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                            <div className="submit-button">
                                <Link to="/" className="primary-bg-color button">
                                    CHIUDI
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withAuthGuard(GestioneSpesa);
