import { persistReducer } from "redux-persist";
import { baseAPI } from "../services/base-api";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../slices/auth/reducer";
import { clearLocalStorage, clearSessionStorage } from "../utils/session-storage";

const persistConfig = {
  key: "root",
  version: 1,
  whitelist: ["auth"],
  storage,
};

const appReducer = combineReducers({
  auth: authReducer,
  [baseAPI.reducerPath]: baseAPI.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;

    clearLocalStorage();
    clearSessionStorage();

  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(baseAPI.middleware),
});
