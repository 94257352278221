import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";
import withAuthGuard from "../../hoc/with-auth-guards";
import LogoImg from "../../assets/logo.png";
import { clearLocalStorage, clearSessionStorage } from "../../utils/session-storage";
import toast from "react-hot-toast";

const Home = () => {
  const role = useSelector((state) => state.auth.role);
  console.log("role", role);

  const buttonNames = [
    { label: "ANAGRAFE DIPENDENTI", path: "/employee-registry", role: ["admin"] },
    { label: "ANAGRAFE FORNITORI", path: "/supply-registry", role: ["admin"] },
    { label: "ANAGRAFE GRUPPI", path: "/group-registry", role: ["admin"] },
    { label: "ANAGRAFE POSIZIONI", path: "/anagrafe-posizioni", role: ["admin"] },
    { label: "ANAGRAFE PRODOTTI", path: "/product-registry", role: ["admin"] },
    { label: "CONTROLLO ASSEGNAZIONI", path: "/assignment-control", role: ["admin"] },
    { label: "INVENTARIO PRODOTTI", path: "/product-inventory", role: ["admin"] },
    { label: "GESTIONE SPESA", path: "/gestione-spesa", role: ["admin"] },
    { label: "AGGIORNA MAGAZZINO", path: "/aggiorna-magazzino", role: ["user"] },
    { label: "ELENCO SPESA", path: "/elenco-spesa", role: ["user"] },
    { label: "MOSTRA MAGAZZINO", path: "/inventario-prodotti", role: ["user"] },
    { label: "NASCONDI ARTICOLI", path: "/nascondi-articoli", role: ["user"] },
    { label: "STAMPA ETICHETTE", path: "/stampa-etichette", role: ["admin", "user"] },
  ];

  const filteredButton = buttonNames.filter((item) => item?.role?.includes(role));

  const handleLogout = () => {
    window.location.href = "/login";
    toast.success("Successfully logged out");
    clearSessionStorage();
    clearLocalStorage();
  };

  return (
    <div className="main-home-wrapper">
      <div className="flex home-logo">
        <img src={LogoImg} alt="" style={{ width: "20% " }} />
      </div>
      <div className="home-wrapper box-shadow">
        <div className="home-content">
          {filteredButton.map((button, index) => (
            <Link to={button.path} key={index} className="button">
              {button.label}
            </Link>
          ))}
          <button type="button" className="button primary-bg-color" onClick={handleLogout}>
            USCITA
          </button>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(Home);
