import React, { useState } from "react";
import { Link } from "react-router-dom/dist";
import { useGetInventoryGruppoQuery, useGetInventoryQuery } from "../../../services/product-inventory/product-inventory-api";
import withAuthGuard from "../../../hoc/with-auth-guards";
import "../styles.scss";
import { useUpdateGroupMutation } from "../../../services/nascondi-articoli/nascondi-articoli-api";
import toast from "react-hot-toast";
import LoadingIcon from "../../../assets/loading.svg";

const NascondiArticoli = () => {
    const [filter, setFilter] = useState({ gruppo: "", tipo: "", prodotto: "" });
    const { gruppo, tipo, prodotto } = filter;
    const { data, isLoading } = useGetInventoryQuery({ ...(tipo && { tipo }), ...(prodotto && { prodotto }) });
    const { data: getMagazzion } = useGetInventoryGruppoQuery({ ...(gruppo && { gruppo }) });
    const [updateNascondi] = useUpdateGroupMutation();
    console.log(getMagazzion);

    const handleInputChange = (event, key) => {
        const value = event.target.value || "";
        setFilter(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleNascondi = async (id) => {
        try {
            await updateNascondi({ id }).unwrap();
            toast.success("Nascondi updated");
        } catch (error) {
            toast.error("Error updating Nascondi. Please try again later.");
        }
    }

    return (
        <div className="wrapper">
            <div className="form-wrapper box-shadow">
                <div className="content">
                    <h2>CONTROLLO ASSEGNAZIONI</h2>
                    <form>
                        {/* form fields */}
                        <div className="form">
                            <input type="text" name="searchProduct" placeholder="CERCA PRODOTTO" value={filter.prodotto} onChange={(e) => handleInputChange(e, "prodotto")} />
                            <div className="form-group">
                                <input name="groupList" placeholder="ELENCO GRUPPO" value={filter.gruppo} onChange={(e) => handleInputChange(e, "gruppo")} />
                                <input type="number" name="productType" placeholder="TIPO PRODOTTO" value={filter.tipo} onChange={(e) => handleInputChange(e, "tipo")} />
                            </div>
                            <div className="form-heading">
                                <h3>ELENCO PRODOTTI</h3>
                                <h3>Nascondi</h3>
                            </div>
                            <div className="content">
                                {isLoading ? (
                                    <div className="flex justify-center">
                                        <img src={LoadingIcon} alt="loading" />
                                    </div>
                                ) : (
                                    <>
                                        {data?.data?.length > 0 ? (
                                            data?.data?.map((item) => (
                                                <div className="item" key={item.id_pe}>
                                                    <p>{item.descrizione}</p>
                                                    <p><button type="button" className="button close-button-bg" onClick={() => handleNascondi(item?.id_ma)}>Nascondi</button></p>
                                                </div>
                                            ))
                                        ) : <div><h5>No results found</h5></div>}
                                    </>
                                )}
                            </div>
                            <div className="submit-button flex">
                                <button type="button" className="primary-bg-color button">
                                    Stampa
                                </button>
                                <Link to="/" className="primary-bg-color button btn-width">
                                    CHIUDI
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withAuthGuard(NascondiArticoli);
