import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import RHFTextField from "../../../components/rhf-textField";
import { Link } from "react-router-dom";
import { useCreateGroupMutation, useDeleteGroupMutation, useGetGroupsQuery, useUpdateGroupMutation } from "../../../services/group-registry/group-registry-api";
import toast from "react-hot-toast";
import withAuthGuard from "../../../hoc/with-auth-guards";
import LoadingIcon from "../../../assets/loading.svg";
import { getSessionStorage } from "../../../utils/session-storage";

const GroupRegistry = () => {
  const user = getSessionStorage("user");
  const [actionType, setActionType] = useState("add");
  const [groupObj, setGroupObj] = useState(null);
  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  const formik = useFormik({
    initialValues: { gruppo: "" },
    validationSchema: Yup.object({
      gruppo: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const gruppoObj = {
          gruppo: values.gruppo,
          database: user?.database,
        };
        if (actionType === "add") {
          await createGroup(gruppoObj).unwrap();
          toast.success("Group created successfully");
        } else if (actionType === "edit") {
          await updateGroup({ id: groupObj.id_gr, ...gruppoObj }).unwrap();
          toast.success("Group updated successfully");
          setActionType("delete");
        }
        formik.resetForm();
      } catch (error) {
        toast.error("An error occurred while processing the request");
      }
    },
  });

  const handleUpdate = (values) => {
    setGroupObj(values);
    setActionType("edit");
    formik.setValues({
      gruppo: values.gruppo,
    });
  };

  const handleDelete = async () => {
    try {
      await deleteGroup({ id: groupObj.id_gr }).unwrap();
      toast.success("Employee deleted successfully");
      setActionType("delete");
      formik.resetForm();
    } catch (error) {
      toast.error("An error occurred while deleting the employee");
    }
  };

  const getValue = formik.getFieldProps("gruppo");
  const { data, isLoading } = useGetGroupsQuery({ search: getValue?.value });

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>ANAGRAFE GRUPPI</h2>
          <form onSubmit={formik.handleSubmit}>
            {/* form fields */}
            <div className="form">
              <div className="form-content">
                <RHFTextField type="text" name="gruppo" placeholder="Descrizione Gruppo" formik={formik} />
                {/* button */}
                <div className="form-group">
                  {actionType === "edit" && (
                    <>
                      <button type="button" className="close-button-bg button" onClick={handleDelete}>
                        CANCELLA
                      </button>
                      <button
                        type="button"
                        className="close-button-bg-color button"
                        onClick={() => {
                          formik.handleReset();
                          setActionType("add");
                        }}
                      >
                        ANNULLA
                      </button>
                    </>
                  )}
                  <button type="submit" className="primary-bg-color button">
                    {actionType === "edit" ? "AGGIORNA" : "SALVA"}
                  </button>
                </div>
              </div>
              <div className="form-heading">
                <h3>ELENCO GRUPPI</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item) => (
                        <div className="item" key={item.id_pe}>
                          <p>{item.gruppo}</p>
                          <div>
                            <button type="button" className="primary-bg-color button" onClick={() => handleUpdate(item)}>
                              MODIFICA
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="submit-button">
                <Link to="/" className="primary-bg-color button">
                  CHIUDI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(GroupRegistry);
