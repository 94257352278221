import React from "react";
import { Input } from "antd";
import { runes } from "runes2";

const RHFTextField = (props) => {
  const { formik, type, name, placeholder, disabled, count = true, rootSx } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value.toUpperCase());
  };

  return (
    <div className="w-100">
      <Input
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={formik.values[name] || ""}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        count={{
          show: count,
          strategy: (txt) => runes(txt).length,
        }}
        {...rootSx}
      />
      {formik.touched[name] && formik.errors[name] ? (
        <div className="error" style={{ display: "block" }}>
          {formik.errors[name]}
        </div>
      ) : null}
    </div>
  );
};

export default RHFTextField;
