import { baseAPI } from "../base-api";
import { SUPPLY } from "../tags";

export const supplyRegistryAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        createSupply: builder.mutation({
            query: ({ ...body }) => ({
                url: "/create-fornitori",
                method: "POST",
                body,
            }),
            invalidatesTags: [SUPPLY]
        }),
        getSupply: builder.query({
            query: () => ({
                url: `/get-fornitori`,
                method: "GET",
            }),
            providesTags: [SUPPLY]
        }),
        updateSupply: builder.mutation({
            query: ({ id, body }) => ({
                url: `/update-fornitori/${id}`,
                method: "PUT",
                ...body
            }),
            invalidatesTags: [SUPPLY]
        }),
        deleteSupply: builder.mutation({
            query: ({ id }) => ({
                url: `/delete-fornitori/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [SUPPLY]
        }),
    }),
});

export const {
    useCreateSupplyMutation,
    useGetSupplyQuery,
    useUpdateSupplyMutation,
    useDeleteSupplyMutation
} = supplyRegistryAPI;
