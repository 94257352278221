import { baseAPI } from "../base-api";
import { POSITION } from "../tags";

export const anagrafePosizioniAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createPosition: builder.mutation({
      query: ({ ...body }) => ({
        url: "/create-posizioni",
        method: "POST",
        body,
      }),
      invalidatesTags: [POSITION],
    }),
    getPosition: builder.query({
      query: () => ({
        url: `/get-posizioni`,
        method: "GET",
      }),
      providesTags: [POSITION],
    }),
    updatePosition: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/update-posizioni/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [POSITION],
    }),
    deletePosition: builder.mutation({
      query: ({ id }) => ({
        url: `/delete-posizioni/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [POSITION],
    }),
  }),
});

export const {
  useCreatePositionMutation,
  useGetPositionQuery,
  useUpdatePositionMutation,
  useDeletePositionMutation
} = anagrafePosizioniAPI;
