import { createSlice } from "@reduxjs/toolkit";
import { setSessionStorage } from "../../utils/session-storage";
import { authAPI } from "../../services/auth-api";

const initialState = {
  isAuthenticated: false,
  accessToken: "",
  user: {},
  role: ''
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = initialState.isAuthenticated;
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authAPI.endpoints.login.matchFulfilled, (state, action) => {
      const data = action.payload;
      state.accessToken = data?.token;
      state.user = data?.user;
      state.role = data?.user?.role;
      state.isAuthenticated = data?.isAuthenticated;
      console.log(data);
      setSessionStorage("accessToken", data?.token);
      setSessionStorage("user", data?.user);
      setSessionStorage("role", data?.user?.role);
      setSessionStorage("isAuthenticated", data?.isAuthenticated);
    });
  },
});

export const authActions = slice.actions;
export const authReducer = slice.reducer;
