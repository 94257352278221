import { baseAPI } from "../base-api";
import { EMPLOYEE } from "../tags";

export const employeeRegistryAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        createEmployee: builder.mutation({
            query: (body) => ({
                url: "/create-personale",
                method: "POST",
                body,
            }),
            invalidatesTags: [EMPLOYEE]
        }),
        getEmployee: builder.query({
            query: ({ search }) => ({
                url: `/get-personale${search ? `?search=${search}` : ''}`,
                method: "GET",
            }),
            providesTags: [EMPLOYEE]
        }),
        updateEmployee: builder.mutation({
            query: ({ id, body }) => ({
                url: `/update-personale/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: [EMPLOYEE]
        }),
        deleteEmployee: builder.mutation({
            query: ({ id }) => ({
                url: `/delete-personale/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [EMPLOYEE]
        }),
    }),
});

export const {
    useCreateEmployeeMutation,
    useGetEmployeeQuery,
    useUpdateEmployeeMutation,
    useDeleteEmployeeMutation
} = employeeRegistryAPI;
