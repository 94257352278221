import { baseAPI } from "../base-api";
import { GROUP } from "../tags";

export const supplyRegistryAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation({
      query: ({ ...body }) => ({
        url: "/create-gruppo",
        method: "POST",
        body,
      }),
      invalidatesTags: [GROUP],
    }),
    getGroups: builder.query({
      query: ({ search }) => ({
        url: `/get-gruppo${search ? `?search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: [GROUP],
    }),
    updateGroup: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/update-gruppo/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [GROUP],
    }),
    deleteGroup: builder.mutation({
      query: ({ id }) => ({
        url: `/delete-gruppo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [GROUP],
    }),
  }),
});

export const { useCreateGroupMutation, useGetGroupsQuery, useUpdateGroupMutation, useDeleteGroupMutation } = supplyRegistryAPI;
