import React, { useState } from "react";
import { Link } from "react-router-dom";
import withAuthGuard from "../../../hoc/with-auth-guards";
import { getSessionStorage } from "../../../utils/session-storage";
// import AggiornaMagazzinoModal from "./aggiorna-magazzino-modal/aggiorna-magazzino-modal";
import LoadingIcon from "../../../assets/loading.svg";
import { useGetMagazzinoFilterQuery } from "../../../services/assignment-control/assignment-control-api";
import TratModal from "./stampa-etichette-modal/trat-modal";
import CongModal from "./stampa-etichette-modal/cong-modal";

const StampaEtichette = () => {
  const [stampaObj, setStampaObj] = useState({});
  const [isStampaOpenModal, setIsStampaOpenModal] = useState(false);
  const [isCongOpenModal, setIsCongOpenModal] = useState(false);
  const [filter, setFilter] = useState({ descrizione: "", tipo: "", gruppo: "" });
  const { descrizione, tipo, gruppo } = filter;
  const { data, isLoading } = useGetMagazzinoFilterQuery({ params: { ...(descrizione && { descrizione }), ...(tipo && { tipo }), ...(gruppo && { gruppo }) } });

  console.log(stampaObj);

  const handleAssignmentChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleStampaClose = () => {
    setIsStampaOpenModal(!isStampaOpenModal);
  };

  const handleCongClose = () => {
    setIsCongOpenModal(!isCongOpenModal);
  };

  return (
    <>
      <div className="wrapper">
        <div className="form-wrapper box-shadow">
          <div className="content">
            <h2>STAMPA ETICHETTE</h2>
            <form>
              {/* form fields */}
              <div className="form">
                <div className="form-content">
                  <input type="text" name="descrizione" placeholder="CERCA PRODOTTO" value={filter.descrizione} onChange={handleAssignmentChange} />
                  <div className="form-group">
                    <select name="gruppo" placeholder="ELENCO GRUPPO" value={filter.gruppo} onChange={handleAssignmentChange}>
                      <option disabled value="">
                        SELEZIONA GRUPPO ...
                      </option>
                      {data?.data?.map((item, index) => (
                        <option key={index} value={item.id_gr}>
                          {item.gruppo}
                        </option>
                      ))}
                    </select>
                    <select name="tipo" placeholder="TIPO PRODOTTO" value={filter.tipo} onChange={handleAssignmentChange}>
                      <option disabled value="">
                        SELEZIONA TIPO ...
                      </option>
                      {data?.data?.map((item, index) => (
                        <option key={index} value={item.id_pr}>
                          {item.tipo}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-heading">
                  <h3>ELENCO PRODOTTI</h3>
                </div>
                <div className="content">
                  {isLoading ? (
                    <div className="flex justify-center">
                      <img src={LoadingIcon} alt="loading" />
                    </div>
                  ) : (
                    <>
                      {data?.data?.length > 0 ? (
                        data?.data?.map((item) => (
                          <div className="item" key={item.id_ma}>
                            <p>{item.descrizione}</p>
                            <div className="flex" style={{ gap: "10px" }}>
                              <button
                                type="button"
                                className="primary-bg-color button"
                                onClick={() => {
                                  setStampaObj(item);
                                  handleCongClose();
                                }}
                              >
                                CONG
                              </button>
                              <button
                                type="button"
                                className="primary-bg-color button"
                                onClick={() => {
                                  setStampaObj(item);
                                  handleStampaClose();
                                }}
                              >
                                TRAT
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <h5>No results found</h5>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="submit-button">
                  <Link to="/" className="primary-bg-color button">
                    CHIUDI
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <TratModal open={isStampaOpenModal} onClose={handleStampaClose} stampaObj={stampaObj} />
      <CongModal open={isCongOpenModal} onClose={handleCongClose} stampaObj={stampaObj} />
    </>
  );
};

export default withAuthGuard(StampaEtichette);
