import { baseAPI } from "../base-api";
import { INVENTORY } from "../tags";

export const productInventoryAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        getInventory: builder.query({
            query: ({ params }) => ({
                url: `/filter-inventory`,
                method: "GET",
                params: {
                    ...(params?.tipo && { tipo: params.tipo }),
                    ...(params?.prodotto && { prodotto: params.prodotto })
                  }
            }),
            providesTags: [INVENTORY]
        }),
        getInventoryGruppo: builder.query({
            query: ({ params }) => ({
                url: "/filter-inventory-by-gruppo",
                method: "GET",
                params: {
                    ...(params?.gruppo && { gruppo: params.gruppo }),
                  }
            }),
            providesTags: [INVENTORY]
        }),
    }),
});

export const {
    useGetInventoryQuery,
    useGetInventoryGruppoQuery
} = productInventoryAPI;
