import { baseAPI } from "../base-api";

export const gestioneSpesaAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
        query: ({ ...body }) => ({
          url: `/send-email`,
          method: "POST",
          body,
        }),
      }),
  }),
});

export const { useSendEmailMutation } = gestioneSpesaAPI;
