import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles.scss";
import withAuthGuard from "../../../hoc/with-auth-guards";
import { useGetUserPersonalDropdownQuery } from "../../../services/dropdown/dropdown-api";
import { getSessionStorage } from "../../../utils/session-storage";
import LoadingIcon from "../../../assets/loading.svg";

const InventarioProdotti = () => {
  const user = getSessionStorage("user");
  const [filter, setFilter] = useState({ descrizione: "", gruppo: "", tipo: "" });
  const { descrizione, gruppo, tipo } = filter;
  const { data, isLoading } = useGetUserPersonalDropdownQuery({ id: user?.id_pe, params: { ...(descrizione && { descrizione }), ...(gruppo && { gruppo }), ...(tipo && { tipo }) } });

  const handleAssignmentChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const printContent = () => {
    window.print();
  };

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>INVENTARIO PRODOTTI</h2>
          <form>
            {/* form fields */}
            <div className="form">
              <input type="text" name="descrizione" placeholder="CERCA PRODOTTO" value={filter.descrizione} onChange={handleAssignmentChange} />
              <select name="employee" placeholder="CERCA DIPEDENTE" value={filter.employee} onChange={handleAssignmentChange}>
                <option disabled value="">
                  SELEZIONA GRUPPO ...
                </option>
                {data?.data?.map((item, index) => (
                  <option key={index} value={item.id_pe}>
                    {item.descrizione}
                  </option>
                ))}
              </select>
              <select name="tipo" placeholder="CERCA DIPEDENTE" value={filter.tipo} onChange={handleAssignmentChange}>
                <option disabled value="">
                  Select ...
                </option>
                {data?.data?.map((item, index) => (
                  <option key={index} value={item.id_gr}>
                    {item.gruppo}
                  </option>
                ))}
              </select>
              <div className="form-heading">
                <h3>ELENCO PRODOTTI</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item) => (
                        <div className="item" key={item.id_pe} style={{ padding: "12px" }}>
                          <p>{item.descrizione}</p>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex button-wrap" style={{ gap: "20px" }}>
                {/* <button type="button" className="primary-bg-color button" onClick={printContent}>
                  STAMPA
                </button> */}
                <Link to="/" className="primary-bg-color button">
                  USCITA
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(InventarioProdotti);
