import React from "react";

const RHFSelect = (props) => {
  const { formik, type, name, placeholder, title, rootSx, children } = props;
  return (
    <div>
      <select type={type} name={name} placeholder={placeholder} value={formik.values[name] || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} {...rootSx}>
      <option disabled value="" selected>
        {title}
      </option>
       {children}
      </select>
      {formik.touched[name] && formik.errors[name] ? <div className="error">{formik.errors[name]}</div> : null}
    </div>
  );
};

export default RHFSelect;
