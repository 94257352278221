export const EMPLOYEE = "EMPLOYEE";
export const SUPPLY = "SUPPLY";
export const GROUP = "GROUP";
export const ASSIGNMENT = "ASSIGNMENT";
export const PRODUCT = "PRODUCT";
export const INVENTORY = "INVENTORY";
export const POSITION = "POSITION";
export const ETICHETTE = "ETICHETTE";
export const USERS = "USERS";

export const TAGS = [USERS, EMPLOYEE, SUPPLY, GROUP, ASSIGNMENT, PRODUCT, INVENTORY, POSITION, ETICHETTE];