import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import RHFTextField from "../../../components/rhf-textField";
import RHFSelect from "../../../components/rhf-select";
import { initialValues, productsDropdown } from "./product-registry.data";
import {
  useAssignProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductQuery,
  useUpdateAssignProductMutation,
  useUpdateProductMutation,
} from "../../../services/product-registry/product-registry-api";
import toast from "react-hot-toast";
import withAuthGuard from "../../../hoc/with-auth-guards";
import { useGetGruppoDropdownQuery, useGetPosizioneDropdownQuery, useGetPersonalDropdownQuery, useGetProvidersDropdownQuery } from "../../../services/dropdown/dropdown-api";
import LoadingIcon from "../../../assets/loading.svg";
import { useNavigate } from "react-router-dom";
import { getSessionStorage } from "../../../utils/session-storage";

const ProductRegistry = () => {
  const user = getSessionStorage("user");
  const [actionType, setActionType] = useState("add");
  const [productObj, setProductObj] = useState(null);
  const [addedButton, setAddedButton] = useState("");
  const { data: getSupplier } = useGetProvidersDropdownQuery({});
  const { data: getGroups } = useGetGruppoDropdownQuery({});
  const { data: getPersonal } = useGetPersonalDropdownQuery({});
  const { data: getPosizione } = useGetPosizioneDropdownQuery({});
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();
  const [assignProduct] = useAssignProductMutation();
  const [updateassignProduct] = useUpdateAssignProductMutation();
  const router = useNavigate();

  const validationSchema = Yup.object({
    suppiler: Yup.string().required("Required"),
    gruppo: Yup.string().required("Required"),
    productType: Yup.string().required("Required"),
    description: Yup.string(),
    um: Yup.string().required("Required"),
    min: Yup.number().required("Required"),
    media: Yup.number().required("Required"),
    posizione: Yup.string(),
    dipendente: addedButton === "salva" ? Yup.string() : Yup.string().required("Required"),
  });

  console.log("getPosizione", getPosizione)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const val = {
        id_gr: values?.gruppo,
        id_fo: values?.suppiler,
        tipo: values?.productType,
        um: values?.um,
        descrizione: values?.description,
        qta_min: values?.min,
        qta_media: values?.media,
        id_po: values?.posizione,
        database: user?.database,
      };

      console.log("values", val)
      console.log("valuesssss", values)
      console.log("dipendente", values?.dipendente);
      console.log("addedButton", addedButton)

      if (addedButton === "salva") {
        try {
          if (actionType === "add") {
            await createProduct(val).unwrap();
            toast.success("Product created successfully");
          } else if (actionType === "edit") {
            await updateProduct({ id: productObj.id_pr, body: val }).unwrap();
            toast.success("Product updated successfully");
            setActionType("delete");
          }
        } catch (error) {
          toast.error("An error occurred while processing the request");
        }
      } else if (addedButton === "assegna") {
        try {
          if (actionType === "add") {
            await assignProduct({ ...val, id_pe: values?.dipendente }).unwrap();
            toast.success("Product created successfully");
          } else if (actionType === "edit") {
            await updateassignProduct({
              id: productObj.id_pr,
              body: {
                ...val,
                id_pe: values?.dipendente,
              },
            }).unwrap();
            toast.success("Product updated successfully");
            setActionType("delete");
          }
        } catch (error) {
          toast.error("An error occurred while processing the request");
        }
      }
    },
  });

  const handleUpdate = (values) => {
    setProductObj(values);
    setActionType("edit");
    formik.setValues({
      fornitore: values?.suppiler,
      tipo: values?.productType,
      um: values?.um,
      descrizione: values?.descrizione,
      min: values?.qta_min,
      media: values?.qta_media,
      posizione: values?.posizione,
      persona: values?.dipendente,
    });
  };

  const handleDelete = async () => {
    try {
      await deleteProduct({ id: productObj.id_pr }).unwrap();
      toast.success("Employee deleted successfully");
      setActionType("delete");
      formik.resetForm();
    } catch (error) {
      toast.error("An error occurred while deleting the employee");
    }
  };

  const getValue = formik.getFieldProps("descrizione");
  const { data, isLoading } = useGetProductQuery({ search: getValue?.value });

  console.log("getGroups", getGroups)

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>ANAGRAFE PRODOTTI</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="form">
              <div className="form-content">
                <RHFSelect type="text" name="suppiler" placeholder="Select Fornitore" formik={formik} title="SELEZIONA FORNITORE">
                  {getSupplier?.data?.map((item, index) => (
                    <option key={index} value={item.id_fo}>
                      {item.ragione}
                    </option>
                  ))}
                </RHFSelect>
                <RHFSelect type="text" name="gruppo" placeholder="Select Gruppo" formik={formik} title="SELEZIONA GRUPPO">
                  {getGroups?.data?.map((item, index) => (
                    <option key={index} value={item.id_gr}>
                      {item.gruppo}
                    </option>
                  ))}
                </RHFSelect>
                <RHFSelect type="text" name="productType" placeholder="Tipo Prodotto" formik={formik} title="SELEZIONA TIPOLOGIA">
                  {productsDropdown?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </RHFSelect>
                <RHFTextField type="text" name="description" placeholder="Descrizione Prodotto" formik={formik} />
                <div className="form-group">
                  <RHFTextField type="string" name="um" placeholder="u.m." formik={formik} />
                  <RHFTextField type="number" placeholder="min" name="min" formik={formik} />
                  <RHFTextField type="number" placeholder="Media" name="media" formik={formik} />
                </div>
                <RHFSelect type="text" placeholder="Elenco Posizioni" name="posizione" formik={formik} title="SELEZIONA POSIZIONE">
                  {getPosizione?.data?.map((item, index) => (
                    <option key={index} value={item.id_po}>
                      {item.posizione}
                    </option>
                  ))}
                </RHFSelect>
                <RHFSelect type="text" placeholder="Select Dipendente" name="dipendente" formik={formik} title="SELEZIONA RESPONSABILE">
                  {getPersonal?.data?.map((item, index) => (
                    <option key={index} value={item.id_pe}>
                      {item.persona}
                    </option>
                  ))}
                </RHFSelect>
                {/* button */}
                <div className="form-group">
                  {actionType === "edit" && (
                    <>
                      <button type="button" className="close-button-bg button" onClick={handleDelete}>
                        CANCELLA
                      </button>
                      <button
                        type="button"
                        className="close-button-bg-color button"
                        onClick={() => {
                          setActionType("add");
                          formik.handleReset();
                        }}
                      >
                        ANNULLA
                      </button>
                    </>
                  )}

                  <button type="submit" className="light-yellow-button-bg button" onClick={() => setAddedButton("assegna")}>
                    ASSEGNA
                  </button>
                  <button type="submit" className="primary-bg-color button" onClick={() => setAddedButton("salva")}>
                    {actionType === "edit" ? "AGGIORNA" : "SALVA"}
                  </button>
                </div>
              </div>
              <div className="form-heading">
                <h3>ELENCO PRODOTTI</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item) => (
                        <div className="item" key={item.id_pe}>
                          <p>{item.descrizione}</p>
                          <div>
                            <button type="button" className="primary-bg-color button" onClick={() => handleUpdate(item)}>
                              MODIFICA
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="submit-button">
                <button type="submit" className="primary-bg-color button" onClick={() => router("/")}>
                  uscita
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(ProductRegistry);
