import React, { useState } from "react";
import { Link } from "react-router-dom";
import withAuthGuard from "../../../hoc/with-auth-guards";
import { useGetProvidersDropdownQuery } from "../../../services/dropdown/dropdown-api";
import LoadingIcon from "../../../assets/loading.svg";

const ElencoSpesa = () => {
  const [ragione, setRagione] = useState("");
  const { data: getProviders, isLoading } = useGetProvidersDropdownQuery({ params: { ...(ragione && { ragione }) } });
  const { data } = useGetProvidersDropdownQuery({});

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>GESTIONE SPESA</h2>
          <form>
            {/* form fields */}
            <div className="form">
              <select name="cercaProdotto" placeholder="ELENCO FORNITORI CON SPESA" value={ragione} onChange={(e) => setRagione(e.target.value)}>
                <option value="" disabled>
                  Select ...
                </option>
                {data?.data?.map((item, index) => (
                  <option key={index} value={item.ragione}>
                    {item.ragione}
                  </option>
                ))}
              </select>
              <div className="form-heading">
                <h3>ELENCO PRODOTTI</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {getProviders?.data?.length > 0 ? (
                      getProviders?.data?.map((item) => (
                        <div className="item" key={item.id_pe} style={{ padding: "12px" }}>
                          <p>{item.ragione}</p>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="submit-button">
                <Link to="/" className="primary-bg-color button">
                  CHIUDI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(ElencoSpesa);
