import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';

const RHFDatePicker = (props) => {
  const { formik, name, placeholder, rootSx, showTime } = props;

  const handleChange = (date) => {
    formik.setFieldValue(name, date);
  };

  return (
    <div className='w-100'>
      <AntDatePicker
        placeholder={placeholder}
        value={formik.values[name]}
        onChange={handleChange}
        showTime={showTime}
        {...rootSx}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className='error' style={{ display: 'block' }}>
          {formik.errors[name]}
        </div>
      )}
    </div>
  );
};

export default RHFDatePicker;
