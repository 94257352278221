import React, { useState } from "react";
import { Link } from "react-router-dom/dist";
import { useGetInventoryGruppoQuery, useGetInventoryQuery } from "../../../services/product-inventory/product-inventory-api";
import withAuthGuard from "../../../hoc/with-auth-guards";
import LoadingIcon from "../../../assets/loading.svg";
import "../styles.scss";

const ProductInventory = () => {
  const [filter, setFilter] = useState({ gruppo: "", tipo: "", prodotto: "" });
  const { gruppo, tipo, prodotto } = filter;
  const { data, isLoading } = useGetInventoryQuery({ params: { ...(tipo && { tipo }), ...(prodotto && { prodotto }) } });
  const { data: getMagazzion } = useGetInventoryGruppoQuery({ params: { ...(gruppo && { gruppo }) } });

  const handleInputChange = (event, key) => {
    const value = event.target.value || "";
    setFilter(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const printContent = () => {
    window.print();
  };

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>INVENTARIO PRODOTTI</h2>
          <form>
            {/* form fields */}
            <div className="form">
              <div className="form-content">
              <input type="text" name="searchProduct" placeholder="CERCA PRODOTTO" value={filter.prodotto} onChange={(e) => handleInputChange(e, "prodotto")} />
              <div className="form-group">
                <select name="groupList" placeholder="ELENCO GRUPPO" value={filter.gruppo} onChange={(e) => handleInputChange(e, "gruppo")}>
                  <option disabled value="" selected>
                  SELEZIONA GRUPPO ...
                  </option>
                  {getMagazzion?.data?.map((item, index) => (
                    <option value={item.id_gr} key={index}>
                      {item.gruppo}
                    </option>
                  ))}
                </select>
                <select name="tipo" placeholder="TIPO PRODOTTO" value={filter.tipo} onChange={(e) => handleInputChange(e, "tipo")}>
                  <option disabled value="" selected>
                    Select ...
                  </option>
                  <option value="1">
                    MATERIA PRIMA
                  </option>
                  <option value="2">
                    PRODUZIONE
                  </option>
                </select>
              </div>
              </div>
              <div className="form-heading">
                <h3>ELENCO PRODOTTI</h3>
                <h3>U.M</h3>
              </div>
              <div className="content">
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={LoadingIcon} alt="loading" />
                  </div>
                ) : (
                  <>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item, index) => (
                        <div className="item" key={index} style={{ padding: "12px" }}>
                          <p>{item.descrizione}</p>
                          <p>{item.um}</p>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5>No results found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="submit-button print-button flex">
                {/* <button type="button" className="primary-bg-color button" onClick={printContent}>
                  Stampa
                </button> */}
                <Link to="/" className="primary-bg-color button btn-width">
                  CHIUDI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(ProductInventory);
