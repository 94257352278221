import React from "react";
import CustomModal from "../../../../components/custom-modal";
import { useFormik } from "formik";
import RHFTextField from "../../../../components/rhf-textField";
import * as Yup from "yup";
import { useUpdateQtaProductMutation } from "../../../../services/product-registry/product-registry-api";

const AggiornaMagazzinoModal = ({ open, onClose, getAggiornaObj }) => {
  const [mutation] = useUpdateQtaProductMutation();
  const validationSchema = Yup.object({
    attuale: Yup.number().required("Attuale is required").integer("Attuale must be an integer"),
    richiesta: Yup.number().required("Richiesta is required").integer("Richiesta must be an integer"),
  });

  const formik = useFormik({
    initialValues: {
      attuale: "",
      richiesta: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await mutation({
          id: getAggiornaObj?.id_ma,
          body: {
            id_pe: getAggiornaObj?.id_pe,
            attuale: values?.attuale,
            richiesta: values?.richiesta
          }
        }).unwrap();
      } catch (error) {

      }
      console.log(values)
      // Logic to increment or decrement values
      // You can perform the desired actions here
    },
  });

  const getAttualeValue = formik.getFieldProps("attuale");
  const getRichiestaValue = formik.getFieldProps("richiesta");

  const handleIncrement = () => {
    const newValue = parseInt(getAttualeValue.value || 0) + 3;
    formik.setFieldValue("attuale", newValue.toString());
  };

  const handleDecrement = () => {
    const currentValue = parseInt(getAttualeValue.value || 0);
    if (currentValue > 0) {
      const newValue = currentValue - 1;
      formik.setFieldValue("attuale", newValue.toString());
    }
  };

  return (
    <CustomModal open={open} onClose={onClose} title="INSERISCI QTA MAGAZZINO">
      <div className="custom-modal">
        <form onSubmit={formik.handleSubmit}>
          <div className="form">
            <RHFTextField type="number" placeholder="Attuale" name="attuale" formik={formik} />
            <RHFTextField type="text" placeholder="RICHIESTA" name="richiesta" formik={formik} />
            {/* buttons */}
            {getAttualeValue?.value && (
              <div className="flex button-wrap">
                <button type="button" className="button light-yellow-button-bg" onClick={handleIncrement}>
                  Aggiungi
                </button>
                <button type="button" className="button light-blue-button-bg" onClick={handleDecrement}>
                  Sottrai
                </button>
              </div>
            )}
            {/* {getRichiestaValue?.value && (
              <div className="flex button-wrap">
                <button type="button" className="button light-yellow-button-bg" onClick={handleIncrement}>
                  Ordina
                </button>
              </div>
            )} */}
            <div className="flex button-wrap">
              <button type="submit" className="button light-purple-button-bg" onClick={handleIncrement}>
                AGGIUNGI E ORDINA
              </button>
              <button type="submit" className="button light-purple-button-bg" onClick={handleDecrement}>
                SOTTRAI E ORDINA
              </button>
            </div>
            {/* close button */}
            <div className="submit-button">
              <button type="button" className="primary-bg-color button" onClick={onClose}>
                ANNULLA
              </button>
            </div>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default AggiornaMagazzinoModal;
