import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../styles.scss";
import { useGetMagazzinoFilterQuery } from "../../../services/assignment-control/assignment-control-api";
import withAuthGuard from "../../../hoc/with-auth-guards";
import LoadingIcon from "../../../assets/loading.svg";
import { useGetPersonalDropdownQuery } from "../../../services/dropdown/dropdown-api";
import jsPDF from "jspdf";

const AssignmentControl = () => {
  const [filter, setFilter] = useState({ descrizione: "", persona: "" });
  const { descrizione, persona } = filter;
  const { data: getPersonal } = useGetPersonalDropdownQuery({});
  const { data, isLoading } = useGetMagazzinoFilterQuery({ params: { ...(descrizione && { descrizione }), ...(persona && { persona }) } });
  const pdfRef = useRef(null);

  const handleAssignmentChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const generatePDF = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      #assignment {
          margin: 10px;
          width: 91.6%;
      }
      .form-heading {
        background-color: #bdbbbb;
        padding-right: 10px;
      }
      h3 {
        padding: 10px 8px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #4e4d4d;
      }
      .item {
        padding: 10px 8px !important;
      }
      .item:nth-child(2n){
        background-color: #d6d6d6;
      }
      .item p {
        font-size: 12px;
        font-weight: 500;
        color: #4e4d4d;
      }

      `;
    document.head.appendChild(style);
    const invoice = new jsPDF("portrait", "px", "a4");
    invoice.html(document.getElementById("assignment")).then(() => {
      invoice.save("invoice.pdf");
      // invoice.autoPrint({variant: 'non-conform'});
      document.head.removeChild(style);
    });
  };

  return (
    <div className="wrapper">
      <div className="form-wrapper box-shadow">
        <div className="content">
          <h2>CONTROLLO ASSEGNAZIONI</h2>
          <form ref={pdfRef}>
            {/* form fields */}
            <div className="form">
              <div className="form-content">
                <input type="text" name="descrizione" placeholder="CERCA PRODOTTO" value={filter.descrizione} onChange={handleAssignmentChange} />
                <select name="persona" placeholder="CERCA DIPEDENTE" value={filter.persona} onChange={handleAssignmentChange}>
                  <option disabled value="">
                    SELEZIONA ADDETTO ...
                  </option>
                  {getPersonal?.data?.map((item, index) => (
                    <option key={index} value={item.id_pe}>
                      {item.persona}
                    </option>
                  ))}
                </select>
              </div>
              <div id="assignment">
                <div className="form-heading">
                  <h3>ELENCO PRODOTTI</h3>
                </div>
                <div className="content">
                  {isLoading ? (
                    <div className="flex justify-center">
                      <img src={LoadingIcon} alt="loading" />
                    </div>
                  ) : (
                    <>
                      {data?.data?.length > 0 ? (
                        data?.data?.map((item) => (
                          <div className="item" key={item.id_pe}>
                            <p>{item.descrizione}</p>
                          </div>
                        ))
                      ) : (
                        <div>
                          <h5>No results found</h5>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="assignment-btn">
                <button type="button" className="button" style={{ background: "rgb(255 98 62)" }} onClick={generatePDF}>
                  Stampa
                </button>
              </div>

              <div className="submit-button">
                <Link to="/" className="primary-bg-color button">
                  CHIUDI
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(AssignmentControl);
