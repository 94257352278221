import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthGuard = (props) => {
  const { children } = props;
  const router = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(false);
  console.log(isAuthenticated);

  useEffect(() => {
    const check = () => {
      if (!isAuthenticated) {
        const searchParams = new URLSearchParams({
          returnTo: (window.location.href = "/login"),
        }).toString();
        router(searchParams);
      } else {
        setChecked(true);
      }
    };

    check();
  }, [isAuthenticated, router]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};
export default AuthGuard;
